import store from "@store/Store.js";
import FlightApi from "@utils/Api/Flight/Index.js";

const handledNumbers = {}

const checkPhoneNumber = (phoneNumber) => {
    handledNumbers[phoneNumber] = null;

    const data = {number: phoneNumber};

    const agent_code = store.getters._getAgentCode;
    if (agent_code) data.agent_code = agent_code;

    FlightApi.checkPhoneNumber(data)
        .then(response => {
            handledNumbers[phoneNumber] = response.data.data;
            if (response.data.data.exists) {
                setPackages(handledNumbers[phoneNumber]);
            }
        })
        .catch(error => {});
}

const setPackages = (data) => {
    if (data.exists){
        const packages = JSON.parse(JSON.stringify(store.getters._getFlightProducts));
        packages.forEach(tempPackage => {
            if (data.packages.includes(tempPackage.id)){
                tempPackage.default_selected = true
            }
        })
        store.commit('setFlightProducts', packages)
    }
}

const checkAndSetPackages = (phoneNumber) => {
    phoneNumber = phoneNumber.replace(/ /g, '');
    if (phoneNumber.length !== 10) return;

    const {_getPageType, _getPageProps} = store.getters;
    if (_getPageType === 'flight' && _getPageProps?.isReservationPage) {
        handledNumbers[phoneNumber]
            ? setPackages(handledNumbers[phoneNumber])
            : checkPhoneNumber(phoneNumber)
    }
}

export default checkAndSetPackages;
