import {Ziggy} from "../../../ziggy.js";

//const phoneNumberWithoutFormat = typeof process !== 'undefined'
//    ? process.env.VITE_SITE_PHONE_NUMBER
//    : import.meta.env.VITE_SITE_PHONE_NUMBER
//
//const formatPhoneNumber = () => {
//    const cleaned = phoneNumberWithoutFormat.replace(/\D/g, '');
//    const number = cleaned.startsWith('90') ? cleaned.slice(2) : cleaned;
//    return number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
//};
//
//const callCenterNumber = formatPhoneNumber()

const SiteSettings = {
    state: {
        callCenterNumber: "850 335 00 00",

        socialMedia: [
            {
                name: "facebook",
                url: "https://www.facebook.com/sorgulamaxcom/",
            },
            {
                name: "twitter",
                url: "https://x.com/sorgulamax_",
            },
            {
                name: "instagram",
                url: "https://www.instagram.com/sorgulamax/",
            },
        ],

        appLinks: {
            appStore: {
                "url": "https://apps.apple.com/us/app/sorgulamax-u%C3%A7ak-otel-otob%C3%BCs/id6739537627",
                "@context": "http://schema.org",
                "@type": "SoftwareApplication",
                "name": "Sorgulamax: Uçak Otel Otobüs",
                "description": "Sorgulamax'ta en avantajlı uçak bileti, otobüs bileti, otel rezervasyonu, araç kiralama fiyatlarını karşılaştırın ve seyahatlerinizde en uygun fiyatı bulun.",
                "screenshot": [
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/fe/e1/00/fee100ae-8a5b-6fce-cfb6-5917a4f187cc/Artboard_5.png/300x0w.jpg",
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/96/44/14/9644147d-9cf6-654e-168e-618d629e8811/Artboard_6.png/300x0w.jpg",
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource211/v4/49/4b/bc/494bbc02-ef06-e80d-5264-fb5b493f6405/Artboard_1.png/300x0w.jpg",
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/43/b2/e7/43b2e7aa-9583-081b-6858-8239bf940ca9/Artboard_3.png/300x0w.jpg",
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/95/99/b1/9599b1a0-0530-104a-2fbb-43df986af5fd/Artboard_4.png/300x0w.jpg",
                    "https://is1-ssl.mzstatic.com/image/thumb/PurpleSource221/v4/95/79/3c/95793c05-3f89-e2f5-b22c-8d2813efc304/Artboard_2.png/300x0w.jpg"
                ],
                "image": "https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/ed/18/12/ed181280-bf41-df7a-e689-8104a9464798/AppIcon-0-0-1x_U007emarketing-0-11-0-85-220.png/1200x630wa.png",
                "applicationCategory": "Travel",
                "datePublished": "Jan 22, 2025",
                "operatingSystem": "Requires iOS 12.0 or later. Compatible with iPhone, iPad, and iPod touch.",
                "author": {
                    "@type": "Person",
                    "name": "Sorgulamax",
                    "url": "https://apps.apple.com/us/developer/sorgulamax/id1785889027"
                },
                "offers": {"@type": "Offer", "price": 0, "priceCurrency": "USD", "category": "free"}
            },
            playStore: {
                "url": "https://play.google.com/store/apps/details?id=com.sorgulamax.apps",
                "@context": "https://schema.org",
                "@type": "SoftwareApplication",
                "name": "Sorgulamax: Uçak Otel Otobüs",
                "description": "Uçak Bileti, Otel, Otobüs, Araç Kiralama",
                "operatingSystem": "ANDROID",
                "applicationCategory": "TRAVEL_AND_LOCAL",
                "image": "https://play-lh.googleusercontent.com/IC9WuavD6UEtmiR4Rj2XioTEDgeG0taz0XgZMMvBw9ysT_uBf4QO_2QJ_y3s2vTY6cLt",
                "contentRating": "PEGI 3",
                "author": {"@type": "Person", "name": "SORGULAMAX", "url": "https://sorgulamax.com/"},
                "offers": [{
                    "@type": "Offer",
                    "price": "0",
                    "priceCurrency": "TRY",
                    "availability": "https://schema.org/InStock"
                }]
            },
            appGallery: "",
        },

        companyInfo: {
            contactPoint: {
                "@type": "ContactPoint",
                telephone: "+908503350000",
                contactType: "customer service",
                areaServed: "TR",
                availableLanguage: "Turkish",
            },
            address: {
                "@type": "PostalAddress",
                addressLocality: "Kocaeli",
                addressCountry: "TR",
                postalCode: "41740",
                streetAddress:
                    "Güney, Sancak Cd. No: 2 Kat: 2, 41740 Körfez/Kocaeli",
            },
            email: typeof process !== "undefined"
                ? process.env.VITE_SITE_EMAIL
                : import.meta.env.VITE_SITE_EMAIL,
            faxNumber: "08503350000",
            url: Ziggy.url,
            name: "Sorgulamax",
            alternateName: "Uçak bileti",
            logo: `${Ziggy.url}/build/static/images/sm-logo-800px.jpg`,
            image: `${Ziggy.url}/build/static/images/sm-logo-800px.jpg`,
            description: "Sorgulamax'ta en avantajlı uçak bileti, otobüs bileti, otel rezervasyonu, araç kiralama fiyatlarını karşılaştırın ve seyahatlerinizde en uygun fiyatı bulun.",
            vatID: "7750920833",
            iso6523Code: "9910:7750920833",
        },
    },

    getters: {
        _getCallCenterNumber: (state) => state.callCenterNumber,

        _getSiteInfos: (state) => ({
            callCenterNumber: state.callCenterNumber,
            socialMedia: state.socialMedia,
            companyInfo: {
                ...state.companyInfo,
                url: Ziggy.url,
            },
        }),

        _getAppInfo: (state) => state.appLinks,
    },
};

export default SiteSettings;
