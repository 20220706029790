import GlobalAxios from "@utils/Api/GlobalAxios.js";
import Helpers from "@helpers/Index.js"

const autocomplete =  (term) => GlobalAxios.Api.get(`/autocomplete/flight-ticket?term=${term}`)

const getFlight = () => GlobalAxios.Api.post('/flight-ticket/get-flights', Helpers.getQueryParams())

const selectFlight = (data) => GlobalAxios.Api.post('/flight-ticket/select-flight', data)

const reservation = (data) => GlobalAxios.Api.post('/flight-ticket/reservation', data)

const getBestPrices = (data) => GlobalAxios.Api.post('/flight-ticket/get-best-prices', data)

const checkPhoneNumber = (data) => GlobalAxios.Api.post('/flight-ticket/phone-number', data)

export default {
    autocomplete,
    getFlight,
    selectFlight,
    reservation,
    getBestPrices,
    checkPhoneNumber,
}
