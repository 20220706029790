const BestPrices = {
    state: {
        bestPrices: {flight: null, car: null, bus: null, hotel: null},

        bestPricesForFlightDatepicker: null,

        bestPricesForBusDatepicker: null,
    },

    mutations: {
        setBestPrices(state, payload){
            state.bestPrices[payload.type] = payload.data
        },

        setBestPricesToEmpty(state){
            state.bestPrices = {flight: null, car: null, bus: null, hotel: null}
        },

        setBestPricesForFlightDatepicker(state, payload){
            state.bestPricesForFlightDatepicker = payload
        },

        setBestPricesForBusDatepicker(state, payload){
            state.bestPricesForBusDatepicker = payload
        }
    },

    getters: {
        _getBestPrices: state => state.bestPrices,

        _getBestPricesForFlightDatepicker: state => state.bestPricesForFlightDatepicker,

        _getBestPricesForBusDatepicker: state => state.bestPricesForBusDatepicker,
    },
}

export default BestPrices
