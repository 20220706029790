import helpers from '@helpers/Index.js'
import checkAndSetPackages from '@helpers/FlightPackagesCheckNumber.js'

const ContactInformation = {
    state: {
        contactInformationFormValues: null,
    },

    mutations: {
        setContactInformationValues(state, values) {
            if (values?.phone?.value?.length === 13){
                checkAndSetPackages(values.phone.value)
            }

            state.contactInformationFormValues = values;
        },

        setContactInformationPhoneNumber(state, phone) {
            state.contactInformationFormValues.phone = phone;

            if (phone?.value?.length === 13){
                checkAndSetPackages(phone.value)
            }

            helpers.localStorage
                .reservationPageUserValues
                .setCommunicationValuesInStorage('phone', phone.value)
        },

        setContactInformationEmail(state, email) {
            state.contactInformationFormValues.email = email
            helpers.localStorage
                .reservationPageUserValues
                .setCommunicationValuesInStorage('email', email.value)
        },

        createEmptyContactInformationFormValues(state) {
            state.contactInformationFormValues = {
                email: {
                    placeholder: 'E-posta adresiniz',
                        placeholderType: 'top',
                        errorMessage: '',
                        result: null,
                        value: '',
                        autocompleteKey: 'email'
                },
                phone: {
                    placeholder: 'Cep Telefonunuz',
                        placeholderType: 'top',
                        errorMessage: '',
                        result: null,
                        value: '',
                        autocompleteKey: 'tel-national'
                },
            }
        },
    },

    getters: {
        _getContactInformationValues: state => state.contactInformationFormValues,

        _getContactInformationPhone: state => state.contactInformationFormValues.phone,

        _getContactInformationEmail: state => state.contactInformationFormValues.email,

        _contactInformationIsCompleted: state => (isEmailRequired = false) => {
            return isEmailRequired ?
                state.contactInformationFormValues.phone.result && state.contactInformationFormValues.email.result :
                state.contactInformationFormValues.phone.result
        },

        _getContactInformationValuesForSend: state => {
            return {
                email: state.contactInformationFormValues.email.value,
                phone: {
                    country_code: '90',
                    number: state.contactInformationFormValues.phone.value.replace(/ /g, '')
                },
            }
        }
    }
}

export default ContactInformation
